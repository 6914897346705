import {
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CookieService } from '../core/services/cookie.service';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const cookieService = inject(CookieService);

  return from(cookieService.getCookie('token')).pipe(
    switchMap((token) => {
      if (!token) {
        return next(req);
      }

      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });

      const newReq = req.clone({
        headers,
      });

      return next(newReq);
    }),
  );
}
