import { Component, DestroyRef, inject } from '@angular/core';
import { IconService } from './core/services/icon.service';
import { MessageService } from './core/services/message.service';
import { AuthHttpService } from './core/services/auth-http.service';
import { tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserActivityService } from './core/services/user-activity.service';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private destroyedRef = inject(DestroyRef);

  constructor(
    private iconService: IconService,
    private authHttpService: AuthHttpService,
    private messageService: MessageService,
    private userActivityService: UserActivityService,
    private router: Router,
  ) {
    if (Capacitor.isNativePlatform()) {
      this.lockOrientation().then(() => {});
      this.manageAppUrlOpen();
    }

    this.iconService.registerSvgIcons();

    this.manageWebSocket();
  }

  private manageWebSocket() {
    this.authHttpService
      .getIsUserConnected()
      .pipe(
        tap((response) => {
          if (response) {
            this.messageService.initWebSocketConnection();
            this.userActivityService.startMonitoringUserActivity();
          } else {
            this.messageService.closeWebSocketConnection();
          }
        }),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe();
  }

  private manageAppUrlOpen() {
    App.addListener('appUrlOpen', (data: any) => {
      if (data.url.includes('canadablog://blog')) {
        this.router.navigateByUrl('/blog');
        return;
      }

      if (data.url.includes('canadablog://compteur')) {
        this.router.navigateByUrl('/compteur');
        return;
      }
    });
  }

  private async lockOrientation() {
    await ScreenOrientation.lock({ orientation: 'portrait' });
  }
}
